import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import './cursosOficinasResidencias.css'

class cursosOficinasResidencias extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <section>
        <Helmet>
          <title>Cursos | Oficinas | Residências - Arte na Rede PI</title>
          <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
          <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, envultações, cursos, oficinas, residências" />
        </Helmet>

        <section className="pre-section" id="oficinas-title">
          <h1 className="about-project oficinas-title" >Cursos | Oficinas | Residências</h1>
        </section>

        <section 
          id="oficinas-dancando-pelo-piaui-container"
        >
          <section className="oficinas-dancando-pelo-piaui-sec-1">
            {/* <h3 className="title-3">O que são?</h3> */}
            <p className="paragrafo">O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados.</p>
          </section>
        </section>

        <section className="cities-sec-2">
          <div className="cidade-blocos">
            <div className="button-saiba-mais">
              <Link id='navItem' className="nav-link active button-cidades" to='/acoes-formativas/cursos-oficinas-residencias/oficina-envultacoes'>
                <div className="cidades-box oficina-envultacoes-btn" />
                <p className="cidades-title">Oficina de Práticas de Envultações</p>
              </Link>
            </div>

            <div className="button-saiba-mais">
              <Link id='navItem' className="nav-link active button-cidades" to='/acoes-formativas/cursos-oficinas-residencias/oficina-danceability'>
                <div className="cidades-box oficina-danceability-btn" />
                <p className="cidades-title">Oficina de Danceability ® Dança para todas as pessoas</p>
              </Link>
            </div>
          </div>                 
        </section>
      </section>
    );
  }
}

export default cursosOficinasResidencias;