import React from "react";
import SiteEmConstrucao from "../../components/items/SiteEmConstrucao";
import { Helmet } from "react-helmet";

class Apresentations extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <main>
                <section>
                <Helmet>
                    <title>Apresentações - Arte na Rede PI</title>
                    <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>
                    <SiteEmConstrucao message="Apresentações" />
                </section>
            </main>
        );
    }
}

export default Apresentations;