import React from 'react';

import './artistas-convidades.css'
import data from '../../data';
import { Helmet } from 'react-helmet';


class Partners extends React.Component {
  componentDidMount() {
      window.scrollTo(0,0);
  }

  render() {
    const { aristasConvidades } = data;

    return (
        <section>
            <Helmet>
                <title>Artistas Convidades - Arte na Rede PI</title>
                <meta name="description" content="Conheça Alguns dos artistas que já participaram do projeto." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas" />
            </Helmet>
            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Artistas Convidades</h1>
            </section>

            <section 
                id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                {/* <h3 className="title-3">O que são?</h3> */}
                    <p className="paragrafo">Conheça Alguns dos artistas que já participaram do projeto.</p>
                </section>
            </section>

            <section className="artistas-sec">
                { Object.entries(aristasConvidades).map((artista) => (
                <div className="artistas-blocos" key={artista[1].name}>
                    <div className="artistas-box">
                        <img className="artista-image" src={`../../../image/artistas-convidades/${artista[1].photo}`} alt={`Foto de ${artista[1].name}`} />
                    </div>
                    <p className="artistas-title">{artista[1].name}</p>
                    <div className="artistas-plus-container">
                        { artista[1].instagram !== undefined && (
                        <a target="_blank" rel="noreferrer" href={artista[1].instagram}>
                            <i className="fa-brands fa-instagram icone-artistas" />
                        </a>) }
                        {/* <i className="fa-solid fa-circle-info icone-artistas" /> */}
                    </div>
                </div>)) }              
            </section>
        </section>
    );
  }
}

export default Partners;