import React from "react";
import { Helmet } from "react-helmet";

class OquePodeUmaProf extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
        <section>
            <Helmet>
                <title>O Que Pode Uma Professora Travesti? - Arte na Rede PI</title>
                <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários" />
            </Helmet>
            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Seminários</h1>
            </section>

            <section 
            id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                    <h3 className="title-3">1ª Seminário Arte na Rede Piauí: O que alimenta a rede?</h3>
                    <h5 className="title-2">Mesa 2: O Que Pode Uma Professora Travesti?</h5>
                </section>
            </section>
        <section className="cities-sec-2">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
                <iframe className="cities-video" src="https://www.youtube.com/embed/aF11IPZG_xQ" title="1º Seminário ARTE NA REDE PI #2 - Mesa 2: O que pode uma professora travesti?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div>
                <h3 className="title-3">Palestrantes</h3>
                <p className="paragrafo">
                    <strong>Profª. Letícia Carolina (PI)</strong><br/>
                    Mulher travesti, negra, gorda, nordestina e de Axé. Autora do livro Transfeminismo. Professora da UFPI e Doutoranda em Educação (UFPI). Ativista do Acolhe Trans e FONATRANS.
                </p>
                <br/>
                <p className="paragrafo">
                    <strong>Ayra Dias (PI)</strong><br/>
                    Graduanda em Serviço Social pela Faculdade de Tecnologia e Educação Superior Profissional - FATESP, Graduanda em Jornalismo pela Universidade Estadual do Piauí, pesquisadora PIBIC da Fiocruz-Pi; ativista dos direitos humanos atuando como membra do Fórum Nacional de Travestis e Transsexuais Negras e Negros - FONATRANS/Pi; Escritora com publicações na Antologia Peles e Nossas Famílias, colunista do jornal O Estado do Piauí,  poeta, fomentadora da cultura Ballrrom, produtora cultural, CEO e Founder da Manicongo Produções.<br/>
                </p>
                <br/>
                <h3 className="title-3">Mediação</h3>
                <p className="paragrafo">
                    <strong>Douglas Emílio (SP)</strong><br/>                    
                    Artista-pesquisadore e Educadore. Doutorande em Dança pela Universidade Federal da Bahia (UFBA); Especialista em Estudos Contemporâneos em Dança (UFBA); Licenciatura em Teatro/ Arte Educação (UNISO) e Pedagogia (FAEP); Formação Método de Reeducação do Movimento – Ivaldo Bertazzo (Escola do Movimento); estudou atuação no Conservatório Dramático e Musical Dr. Carlos de Campos de Tatuí; fez intercambio com as Escolas Trinity Laban Centre (Londres), Summer School P.A.R.T.S (Bruxelas) e Les Ballets C de la B (Gent). Idealizou e faz as mediações do Projeto: Incubadora de estudos performativos – SESC/Sorocaba (2019) e Festival de Artes Híbridas (2021). Integrante do grupo de pesquisa Ágora: modos de ser em Dança (PPGDança – UFBA/CNPq). Assistente de produção e curadoria do Festival Internacional de Dança de Araraquara (2017 a 2021). Conjuntamente a Profa. Dra. Gilsamara Moura organiza a série de livros “Ágora: modos de ser em Dança” (vol. 1, 2, 3 e 4).<br/>
                </p>
            </div>
        </section>
      </section>
    );
  }
}
    
export default OquePodeUmaProf;