import React from "react";

import PhotoMural from "../../../../components/PhotoMural/PhotoMural";
import data from "../../../../data";

import { Helmet } from "react-helmet";

class OficinaDanceability extends React.Component {
  constructor () {
    super();

    this.state = {
        imageIndex: 0,
        allPhotos: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  handleClick(dados) { 
    return (button) => {
        const limitReference = dados.photos.length - 1;
        console.log('handle click');
        switch (button) {
            case 'next':
                return this.setState((prevState) => ({
                    imageIndex: prevState.imageIndex === limitReference ? 0 : prevState.imageIndex + 1,
                }));
            case 'previous':
                return this.setState((prevState) => ({
                    imageIndex: prevState.imageIndex === 0 ? limitReference : prevState.imageIndex - 1,
                }));
            case 'all-photos':
                return this.setState((prevState) => ({
                    allPhotos: !prevState.allPhotos,
                }));
            default:
                break;
        }
    }
}

  render() {
    const { cursosOficinasResidencias } = data;
    const { oficinaDanceability } = cursosOficinasResidencias;
    const { imageIndex, allPhotos } = this.state;
    return (
        <section>
            <Helmet>
                <title>Oficina de DanceAbility® - Arte na Rede PI</title>
                <meta name="description" content="Método para dança, movimento e comunicação não-verbal que integra pessoas com e sem deficiência através da linguagem da improvisação, criado pelo coreógrafo e dançarino Alito Alessi (USA) nos anos 80." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências" />
            </Helmet>

            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Cursos | Oficinas | Residências</h1>
            </section>

            <section 
            id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                    <h3 className="title-3">Oficina DanceAbility® - Dança Para Todas as Pessoas</h3>
                    <p className="paragrafo">Método para dança, movimento e comunicação não-verbal que integra pessoas com e sem deficiência através da linguagem da improvisação, criado pelo coreógrafo e dançarino Alito Alessi (USA) nos anos 80.</p>
                </section>
            </section>
        <section className="cities-sec-2">
            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
                <h5 className="title-2">Mesa 1: Pensamentos Decoloniais</h5>
                <iframe className="cities-video" src="https://www.youtube.com/embed/mWFvKcfSvKk" title="1º Seminário ARTE NA REDE PI #2 - Mesa 1: Pensamentos Decoloniais" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div> */}

            <section className="cities-sec-3">
                    <PhotoMural
                        imageLink={ `../../image/acoes-formativas/cursos-oficinas-residencias/oficina-danceability/${oficinaDanceability.photos[imageIndex]}` }
                        imageDescription='Teresina'
                        handleClick={ this.handleClick(oficinaDanceability) }
                        allPhotos={ allPhotos }
                    />
                    {/* <div className='all-photos-general'>
                    {allPhotos
                        && (
                            oficinaDanceability.photos.map((photo) => {
                            return (
                                <div className='all-photos-container'  key={ photo }>
                                    <div className='all-photo-container'>
                                        <img
                                            className='all-photos-image'
                                            src={`../../image/acoes-formativas/cursos-oficinas-residencias/oficina-danceability/${photo}`}
                                            alt={ 'Oficina de DanceAbility®' }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )}
                    </div> */}

                    <p className="paragrafo">A oficina foi ministrada por Letícia Sekito da Companhia Flutuante(SP) na qual alguns princípios básicos do método serão comartilhados através de orientações verbais e demonstração corporal que instrumentalizem a capacidade de cada pessoa sintonizar-se com suas percepções e sensações físicas. A oficina também pretende expandir e ampliar a capacidade de relacionar-se com o espaço e com a arquitetura disponível a cada aula, experimentando diferentes noções de tempo e dinâmicas de movimento de cada participante com as devidas adaptações para a atividade acontecer presencialmente.
                    </p>
                </section>

            <p><strong>Período:</strong> 9h às 12h30<br/><strong>Duração:</strong> 31 de janeiro a 4 de fevereiro de 2022</p>
        </section>
      </section>
    );
  }
}
    
export default OficinaDanceability;