import React from 'react';
import '../City.css';

import PhotoMural from '../../PhotoMural/PhotoMural';
import { Helmet } from 'react-helmet';

import data from '../../../data';

class Currais extends React.Component {
    constructor () {
        super();

        this.state = {
            imageIndex: 0,
            allPhotos: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    handleClick(dados) { 
        return (button) => {
            const limitReference = dados.photos.length - 1;
            console.log('handle click');
            switch (button) {
                case 'next':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === limitReference ? 0 : prevState.imageIndex + 1,
                    }));
                case 'previous':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === 0 ? limitReference : prevState.imageIndex - 1,
                    }));
                case 'all-photos':
                return this.setState((prevState) => ({
                    allPhotos: !prevState.allPhotos,
                }));
                default:
                    break;
            }
        }
    }

    render() {
        const { currais } = data;
        const { imageIndex, allPhotos } = this.state;
        return (
            <section>
                <Helmet>
                    <title>Currais - Arte na Rede PI</title>
                    <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>

                <section
                    style={{
                        backgroundImage: 'url("../../image/acoes-formativas/oficinas-dancando-pelo-piaui/cidades/currais-1.jpg")',
                        backgroundPosition: '0 40%',
                    }}
                    className="cities-sec-1"
                >
                    <div className="cities-text-1">
                        <h3 className="title-3">Currais</h3>
                        <p className="paragrafo">A oficina Dançando Pelo Piauí esteve na cidade entre os dias 06 a 08 de junho. Confira um pouco do que aconteceu.</p>
                    </div>
                </section>

                <section className="cities-sec-3">
                    <PhotoMural
                        imageLink={ `../../image/photos/currais/${currais.photos[imageIndex]}` }
                        imageDescription='Currais'
                        handleClick={ this.handleClick(currais) }
                    />

                    {/* <div className='all-photos-general'>
                    {allPhotos
                        && (
                        currais.photos.map((photo) => {
                            return (
                                <div className='all-photos-container' key={ photo }>
                                    <div className='all-photo-container'>
                                        <img
                                            className='all-photos-image'
                                            src={`../../image/photos/currais/${photo}`}
                                            alt={ 'Projeto Arte na Rede Piauí na cidade de Currais' }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )}
                    </div> */}
                </section>
            </section>
        );
    }
};

export default Currais;