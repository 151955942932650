import React from "react";
import { Helmet } from "react-helmet";

class PoliticasPublicasCulturais extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
        <section>
            <Helmet>
                <title>Políticas Públicas Culturais - Arte na Rede PI</title>
                <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
            </Helmet>

            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Seminários</h1>
            </section>

            <section 
            id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                    <h3 className="title-3">1ª Seminário Arte na Rede Piauí: O que alimenta a rede?</h3>
                    <h5 className="title-2">Mesa 3: Políticas Públicas Culturais</h5>
                </section>
            </section>
        <section className="cities-sec-2">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
                <iframe className="cities-video" src="https://www.youtube.com/embed/5VIUqM3dZdw" title="1º Seminário ARTE NA REDE PI #2 - Mesa 3: Políticas Públicas Culturais" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div>
                <h3 className="title-3">Palestrantes</h3>
                <p className="paragrafo">
                    <strong>Roberto Freitas (PI)</strong><br/>
                    Doutorando em Dança (PPGDANÇA/UFBA/2020). Mestre em Artes Cênicas (PPGCEN/UnB/2017). Especialista em Metodologia de Ensino Fundamental, Médio e Superior (FAESPI/2003).  Graduado em Licenciatura Plena em Educação Física (UFPI/1997).  É professor da Rede Pública Municipal de Ensino de Teresina (SEMEC/2000), na qual realiza a formação continuada de professores/as para o ensino de Dança nas Escolas de Tempo Integral. Foi professor nas IES: Universidade Paulista (UNIP/2018-2019); Centro Universitário UNINOVAFAPI (2008-2010); e Universidade Federal do Piauí (UFPI/Substituto 1997-1999). Também atuou como: diretor do Balé da Cidade de Teresina (2005-2008 e 2011-2014); Coordenador Artístico da Escola Estadual de Dança Lenir Argento (Piauí/2008-2009). Fundador e Coordenador do Cordão Grupo de Dança, por meio do qual realiza premiado trabalho de Arte, Educação e Inclusão, Social e Educacional. Presidente da ACORDA - Associação dos Amigos do Cordão Grupo de Dança. Idealizador e Coordenador de projetos como: Cordão de Cultura – Como se produz um/a artista? (2021); Andanças (2016); Estação Cordão de Cultura (2014-2015); Educação se faz com Arte/Dança (2006 e 2008); dentre outros.
                    <br/>
                    <br/>
                    Autor dos livros: 
                    “Produção textual em Dança escrita por piauienses” (2021); “Corpo~Cordão: cartografias de jovens dançantes em Teresina-Piauí” (2021); “Componente Curricular Dança: uma proposta para o Ensino Fundamental” (2020), “Arte, Educação e Inclusão: 10 anos de Cordão Grupo de Dança” (2018); e, “OUSADIA: Vinte anos de história do Balé da Cidade de Teresina” (2014), dentre outras publicações.<br/>
                </p>
                <br/>
                <p className="paragrafo">
                    <strong>Gyl Giffony (CE)</strong><br/>
                    É artista da Inquieta Cia, de Fortaleza, Ceará, e membro do Instituto Brasileiro de Direitos Culturais (IBDCult). Vive e se move com as Artes Vivas, Memória Social, Direitos Culturais, Gestão e Produção Cultural, articulando criações artísticas e ações pedagógicas, trabalhando junto a grupos, artistas, instituições e movimentos socioartísticos. É doutor em Artes da Cena pela Universidade Estadual de Campinas (Unicamp), com estágio de investigação na Pontificia Universidad Católica de Chile, e autor do livro "De quem é a cena? A regulamentação do exercício amador e profissional de atores e atrizes".<br/>
                </p>
                <br/>
                <h3 className="title-3">Mediação</h3>
                <p className="paragrafo">
                    <strong>Hildegarda Sampaio (PI)</strong><br/>
                    É artista, gestora e produtora cultural, mestre em Dança (UFBA), especialista em Estudos Contemporâneos em Dança (UFBA) e pós graduanda em Gestão Cultural, pesquisa sobre micropolíticas e gestão de redes colaborativas. É coordenadora do Programa Cultura no Sesc Piauí, do Projeto Arte na Rede e da plataforma Com.Dança, pensando espaços de diálogos, trocas, estudos e proposições sobre dança, produção cultural e políticas de cultura.<br/>
                </p>
            </div>
        </section>
      </section>
    );
  }
}
    
export default PoliticasPublicasCulturais;