import React from "react";
import { Helmet } from "react-helmet";

import data from "../../data";

class Occupe extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const { narrativasEnviesadas } = data;
        return (
            <div>
                <Helmet>
                    <title>Ocupa a Rede - Arte na Rede PI</title>
                    <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>

                <section className="pre-section" id="oficinas-title">
                    <h1 className="about-project oficinas-title" >Ocupa a Rede</h1>
                </section>

                <section 
                    id="oficinas-dancando-pelo-piaui-container"
                >
                    <section className="oficinas-dancando-pelo-piaui-sec-1">
                        <h3 className="title-3">Narrativas Enviesadas</h3>
                        {/* <h5 className="title-2">Mesa 2: O Que Pode Uma Professora Travesti?</h5> */}
                    </section>
                </section>

                <section>
                    { Object.values(narrativasEnviesadas).map((item) => (
                        <div key={ item.link } style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '50px 0' }}>
                            <h2 className="title-2"><strong>{ item.city }</strong></h2>
                            
                            {item.links.map((link, index) => (
                                <iframe className="cities-video" style={{ marginBottom: '15px'}} src={ link } title={ `item.name - ${index}` } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                            )) }

                            <div>
                                { Object.values(item.peoples).map((people) => (
                                    <p key={ people.name } style={{
                                        marginBottom: '2px',
                                        padding: '0 50px'
                                    }} ><strong>{ people.name }</strong> - { people.description }</p>
                                )) }
                            </div>
                        </div>)) }
                </section>
            </div>
        );
    }
}

export default Occupe;