import React from "react";
import MiniSaibaMais from "../../components/MiniSaibaMais/MiniSaibaMais";
import { Helmet } from "react-helmet";

import '../sobre-o-projeto/sobre-o-projeto.css'



class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render () {
    return (
        <section>
            <Helmet>
                <title>Sobre o Projeto - Arte na Rede PI</title>
                <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí" />
            </Helmet>

            <section className="pre-section" id="about-title">
                <h1 className="about-project" >Sobre o projeto</h1>
            </section>
            <section id="about-project" className="about-sec-1">
                <div className="about-text-1">
                        <h3 className="title-3">O que é o Arte na Rede PI?</h3>
                        <p className="paragrafo">O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados.</p>
                </div>

                <div>
                    <iframe className="cities-video" src="https://www.youtube.com/embed/2yLXmXUpUIw" title="TEASER 11   EQUIPE DIRETORA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </section>

            <section className="about-sec-2">
                <div>
                    <img className="about-photos" src="../image/sobre-o-projeto/fotos copy.png" alt="fotos do grupo" />
                </div>

                <div className="about-text-2">
                    <h3 className="title-3">Conheça o projeto</h3>
                    <p className="paragrafo">O Projeto contribuirá para a formação de novos artistas, que bem representam a cultura piauiense.</p>
                    
                    
                    <p className="paragrafo">O Projeto ARTE NA REDE é uma iniciativa arrojada, pelo alcance, difusão e democratização do acesso a bens culturais de qualidade que promove. Busca investir nos artistas piauienses, entendendo-se este mercado como a combinação: público / viabilidade econômica. Propõe, dessa forma, uma programação com artistas locais e nacionais gratuitos.</p>

                    <p className="paragrafo">O Estado do Piauí é muito rico em manifestações culturais. Como o estado é relativamente grande, havendo vários tipos de clima, vegetação e relevo, é comum a variedade de culturas conforme o local. As manifestações mais comuns no Piauí são: Bumba-Meu-Boi, Cavalo Piancó, Congada, Samba de Cumbuca, Reisado, bandolins, rabecas, entre outros. De Norte a Sul do Piauí, o fazer cultural característico do povo piauiense é apresentado de diversas formas. Seja na música, dança, teatro ou até nas misturas dessas manifestações culturais é que se encontra a nossa identidade. Em festivais que ultrapassam a capital Teresina, a cultura é preservada por meio do fomento de diferentes tipos de arte e apresentações pelo estado.</p>

                    <div>
                        <h3 className="title-3">Ficha Técnica</h3>
                        <p className="paragrafo">
                        <strong>Direção Geral:</strong> Datan Izaká<br/>
                        <strong>Direção Artística:</strong> Hildegarda Sampaio<br/>
                        <strong>Oficineiros:</strong> Datan Izaká e Paulo Beltrão<br/>
                        </p>
                    </div>
                </div>
            </section>

            <section className="cities-sec-2">
                <div>
                    <h3 className="title-3">Quem somos?</h3>
                    <p className="paragrafo">
                    A Associação de Promoção Multicultural – PROMULTI, empresa sem fins lucrativos com a finalidade de apoiar ações de incentivo ao empreendedorismo nas mais diversas áreas da cultura e arte, além de outras áreas que contribuam com o desenvolvimento econômico e inclusão social, formada por um grupo de profissionais autônomos, nas mais diversas áreas, e dotada de objetivos voltados a promoção de atividades e finalidades de relevância publica e social com a proposta de promover a produção artística, cultural e valorização do patrimônio material e imaterial em todo o território nacional e internacional.

                    Desde de 2015 vem realizando projetos em parceria com outras instituições e espaços que se relacionam com arte e cultura em geral. Produziu o Projeto Quintura tendo o mesmo ocorrido no Espaço Cultural SOBRADO.

                    Desde 2017 vem realizando e produzindo Projetos como o REDEMOINHO DE DANÇA (2017-2021), BOCA DA NOITE 2020 – Edição CAPITAL e BOCA DA NOITE 2020 – Edição INTERIOR, MARIA BONITA DANÇA edição 2020, RESIDÊNCIA ARTÍSTICA REDEMOINHO DE DANÇA 2021, ARTE NA REDE: formação, difusão e ampliação do ensino das artes cênicas no Estado do Piauí.

                    Até o final de 2021 serão aproximadamente 500 pessoas envolvidas e contratadas direta e indiretamente, entre músicos, bailarinos, professores, artistas, técnicos, produtores, empresas de culinárias, etc., ou seja, toda uma cadeia produtiva participante
                    </p>
                </div>

                    <div>
                        <iframe className="cities-video" src="https://www.youtube.com/embed/pwXzpaBo0ZA" title="TEASER 14   PATROCINADORES" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </section>
            
            <div>
                <img className="a-arte-transforma" src="./image/sobre-o-projeto/a-arte-transforma.jpg" alt="a-arte-transforma" />
            </div>

            <section>
                <MiniSaibaMais />
            </section>
        </section>
    );
    }
}

export default About;