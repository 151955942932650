import React from "react";
import MiniAcoesFormativas from "../../components/MiniAcoesFormativas/MiniAcoesFormativas";
import './AcoesFormativas.css'

import { Helmet } from "react-helmet";

class Formative extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render () {
    return (
        (<section>
            <Helmet>
                <title>Ações Formativas - Arte na Rede PI</title>
                <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas" />
            </Helmet>
            <section className="pre-section" id="about-title">
                <h1 className="about-project" >Ações formativas</h1>
            </section>

            <section 
                id="acoes-formativas-container"
                
                >
                <section className="acoes-formativas-sec-1">
                    {/* <h3 className="title-3">O que é o Arte na Rede Pi?</h3> */}
                    <p className="paragrafo">Ações destinadas a atistas e população geral, que visam a conscientização para a importância da arte e da cultura por intermédio do produto cultural do projeto.</p>
                </section>

                <MiniAcoesFormativas />
            </section>
        </section>)
        // : <Outlet /> )
    );
    }
}

export default Formative;