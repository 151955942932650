import React from 'react';
import '../City.css';

import PhotoMural from '../../PhotoMural/PhotoMural';
import { Helmet } from 'react-helmet';

import data from '../../../data';

class União extends React.Component {
    constructor () {
        super();

        this.state = {
            imageIndex: 0,
            allPhotos: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    handleClick(dados) { 
        return (button) => {
            const limitReference = dados.photos.length - 1;
            console.log('handle click');
            switch (button) {
                case 'next':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === limitReference ? 0 : prevState.imageIndex + 1,
                    }));
                case 'previous':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === 0 ? limitReference : prevState.imageIndex - 1,
                    }));
                case 'all-photos':
                return this.setState((prevState) => ({
                    allPhotos: !prevState.allPhotos,
                }));
                default:
                    break;
            }
        }
    }

    render () {
    const { uniao } = data;
    const { imageIndex, allPhotos } = this.state;
        return (
            <section>
                <Helmet>
                    <title>União - Arte na Rede PI</title>
                    <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>

                <section
                    style={{
                        backgroundImage: 'url("../../image/acoes-formativas/oficinas-dancando-pelo-piaui/cidades/União-1.jpg")',
                        backgroundPosition: '45%',
                    }}
                    className="cities-sec-1"
                >
                    <div className="cities-text-1">
                        <h3 className="title-3">União</h3>
                        <p className="paragrafo">A oficina Dançando Pelo Piauí esteve na cidade entre os dias 19 a 21 de maio no Centro Cultural de União, ministrada pelo bailarino e coreógrafo Paulim Beltrão com a apresentação artística da obra "Aparições de Dança" do Bailarino e Coreógrafo Datan Izaká no dia 20 de maio. Confira um pouco do que aconteceu.</p>
                    </div>
                </section>

                <section className="cities-sec-2">
                    <iframe className="cities-video" src="https://www.youtube.com/embed/8JYKo1ueiTk" title="TEASER 3   UNIÃO" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </section>

                <section className="cities-sec-3">
                    <PhotoMural
                        imageLink={ `../../image/photos/uniao/${uniao.photos[imageIndex]}` }
                        imageDescription='União'
                        handleClick={ this.handleClick(uniao) }
                        allPhotos={ allPhotos }
                    />

                    {/* <div className='all-photos-general'>
                    {allPhotos
                        && (
                        uniao.photos.map((photo) => {
                            return (
                                <div className='all-photos-container'  key={ photo }>
                                    <div className='all-photo-container'>
                                        <img
                                            className='all-photos-image'
                                            src={`../../image/photos/uniao/${photo}`}
                                                alt={ 'Projeto Arte na Rede Piauí na cidade de União' }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )}
                    </div> */}
                </section>
            </section>
        );
    }
};

export default União;