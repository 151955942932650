import { Switch, Route } from 'react-router-dom';

import Home from './pages/Inicio/Inicio';
import About from './pages/sobre-o-projeto/sobre-o-projeto';
import Occupe from './pages/ocupa-a-rede/ocupa-a-rede';
import Apresentations from './pages/apresentacoes/apresentacoes';
import Formative from './pages/acoes-formativas/acoes-formativas';
    import cursosOficinasResidencias from './pages/acoes-formativas/outras-formacoes/cursosOficinasResidencias';
        import OficinaDeEnvultacoes from './pages/acoes-formativas/outras-formacoes/oficina-pratica-de-envultacoes/OficinaDeEnvultacoes';
        import OficinaDanceability from './pages/acoes-formativas/outras-formacoes/oficina-danceability/oficinaDanceability';
        
    import DancingPiaui from './pages/acoes-formativas/oficinas-dançando-pelo-piauí/oficinas-dançando-pelo-piauí';
        import Teresina from './components/Cidades/Teresina/Teresina';
        import União from './components/Cidades/União/União';
        import CasteloDoPiaui from './components/Cidades/Castelo-do-piaui/CasteloDoPiaui';
        import SaoJoaoDaSerra from './components/Cidades/Sao-joao-da-Serra/SaoJoaoDaSerra';
        import Cabeceiras from './components/Cidades/Cabeceiras/Cabeceiras';
        import JoseDeFreitas from './components/Cidades/Jose-de-freitas/JoseDeFreitas';
        import BomJesus from './components/Cidades/Bom-jesus/BomJesus';
        import Currais from './components/Cidades/Currais/Currais';
        import SaoRaimundoNonato from './components/Cidades/Sao-raimundo-nonato/sao-raimundo-nonato';
        import Floriano from './components/Cidades/Floriano/Floriano';
        import Parnaiba from './components/Cidades/Parnaiba/Parnaiba';
    import Seminaries from './pages/acoes-formativas/seminarios/seminarios';
        import PensamentosDecoloniais from './pages/acoes-formativas/seminarios/primeiro-seminario/PensamentosDecoloniais';
        import OquePodeUmaProf from './pages/acoes-formativas/seminarios/primeiro-seminario/OquePodeUmaProfessora';
        import PoliticasPublicasCulturais from './pages/acoes-formativas/seminarios/primeiro-seminario/PoliticasPublicasCulturais';

        import error404 from './pages/404/404';

// import SiteEmConstrucao from './components/items/SiteEmConstrucao';

import Partners from './pages/artistas-convidades/artistas-convidades';
import React from 'react';

class Content extends React.Component {
    render() {
    return (
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/sobre-o-projeto' component={About} />
                <Route exact path='/acoes-formativas' component={Formative} />

                    <Route exact path='/acoes-formativas/dancando-pelo-piaui' component={DancingPiaui} />

                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/teresina' component={Teresina} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/uniao' component={União} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/castelo-do-piaui' component={CasteloDoPiaui} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/sao-joao-da-serra' component={SaoJoaoDaSerra} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/cabeceiras' component={Cabeceiras} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/jose-de-freitas' component={JoseDeFreitas} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/bom-jesus' component={BomJesus} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/currais' component={Currais} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/sao-raimundo-nonato' component={SaoRaimundoNonato} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/floriano' component={Floriano} />
                        <Route exact path='/acoes-formativas/dancando-pelo-piaui/parnaiba' component={Parnaiba} />

                    <Route exact path='/acoes-formativas/cursos-oficinas-residencias' component={cursosOficinasResidencias} />
                        <Route exact path='/acoes-formativas/cursos-oficinas-residencias/oficina-envultacoes' component={OficinaDeEnvultacoes} />
                        <Route exact path='/acoes-formativas/cursos-oficinas-residencias/oficina-danceability' component={OficinaDanceability} />
                    
                    <Route exact path='/acoes-formativas/seminarios' component={Seminaries} />
                        <Route exact path='/acoes-formativas/seminarios/pensamentos-decoloniais' component={PensamentosDecoloniais} />
                        <Route exact path='/acoes-formativas/seminarios/o-que-pode-uma-professora-travesti' component={OquePodeUmaProf} />
                        <Route exact path='/acoes-formativas/seminarios/politicas-publicas-culturais' component={PoliticasPublicasCulturais} />

                <Route exact path='/ocupa-a-rede' component={Occupe} />
                <Route exact path='/apresentacoes' component={Apresentations} />
                <Route exact path='/artistas-convidades' component={Partners} />
                <Route exact path='*' component={error404} />
            </Switch>
    );
    }
}

export default Content;
