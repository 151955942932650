import React from "react";
import { Helmet } from "react-helmet";

class PensamentosDecoloniais extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
        <section>
            <Helmet>
                <title>Pensamentos Decoloniais - Arte na Rede PI</title>
                <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários" />
            </Helmet>
            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Seminários</h1>
            </section>

            <section 
            id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                    <h3 className="title-3">1ª Seminário Arte na Rede Piauí: O que alimenta a rede?</h3>
                    <h5 className="title-2">Mesa 1: Pensamentos Decoloniais</h5>
                </section>
            </section>
        <section className="cities-sec-2">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
                <h5 className="title-2">Mesa 1: Pensamentos Decoloniais</h5>
                <iframe className="cities-video" src="https://www.youtube.com/embed/mWFvKcfSvKk" title="1º Seminário ARTE NA REDE PI #2 - Mesa 1: Pensamentos Decoloniais" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>

            <div>
                <h3 className="title-3">Palestrantes</h3>
                <p className="paragrafo">
                    <strong>Amélia Conrado (BA)</strong><br/>
                    É pós-doutora em Artes das Imagens e Arte Contemporânea pela Universidade de Paris 8- França. É Doutora e Mestra em Educação pelo Programa de Pós-Graduação em Educação da Universidade Federal da Bahia(UFBA). Atualmente é Professora da Escola de Dança da Universidade Federal da Bahia e integra os Programas de Pós-graduação em Dança e Mestrado Profissional em Dança da UFBA. Atuou por 19 anos como Professora da Faculdade de Educação da UFBA. É líder do Grupo de Pesquisa GIRA- culturas indígenas, repertórios afrobrasileiros e populares. Recebeu em 2014 o Premio de Professor Visitante Ilustre pelo Five College Latin American, Caribbean and Latino Studies (Massachusetts Amherst -USA). É autora de livros e artigos especializados em danças afrobrasileiras e culturas negras. Colaboradora nas ações artístico-culturais do Bloco Afro Ilê Aiyê.
                </p>
                <br/>
                <p className="paragrafo">
                    <strong>Rui Moreira (SP)</strong><br/>                    
                    Trabalhador da Dança, é ativista pelo direito de fruição e amplitude social das artes. Desenvolve investigação gestual focada nas expressões tradicionais patrimoniais, populares e contemporâneas do Brasil, tendo como principal atenção o diálogo envolvendo corpos, culturas e memórias como dispositivo para as suas pesquisas criativas. Compôs renomados elencos de companhias nacionais e internacionais, fundou e dirigiu em Belo Horizonte a Cia. SeráQuê? a Associação SeráQuê Cultural e criou a Rui Moreira Cia de danças, focado na produção de espetáculos, promoções de oficinas e seminários sobre novos e melhores rumos sociais para as artes com foco hegemônico em dança. Também em Belo Horizonte, no ano de 2003, iniciou uma trajetória como curador e de diretor artístico de eventos através do FAN - Festival Internacional de Arte Negra.  Idealizou e realizou os encontros da Rede Terreiro Contemporâneo de Danças e fiz curadoria do Festival de Dança de Joinville. No campo da educação, como artista convidado ministrou o módulo Corporeidades Negras na Fundação Educacional de Macaé (RJ) e Performance e ritual na PUC Minas Gerais. É brasileiro, original de São Paulo, morou em Belo Horizonte, Lyon (França) e desde 2017 reside em Porto Alegre onde cursa na UFRGS o curso de licenciatura em dança.<br/>
                </p>
                <br/>
                <h3 className="title-3">Mediação</h3>
                <p className="paragrafo">
                    <strong>Mallu Mendes (PI)</strong><br/>                                        
                    Maria Luisa Mendes, Mallu Mendes, artista negra e comunicadora Social piauiense, formada na Escola de Teatro Gomes Campos, Especialista em Dança Contemporânea- UFBA, especialista em Direitos  Humanos-Esperança Garcia. Vivência no programa de pós-Graduação Arte da Cena-UFJR, formação em dança através de vivência/oficinas/participação em trabalhos artistas piauienses como Marcelo Evelin (Batudada), Soraya Portela (Sacolandia), Elielson Pacheco(Grito), além de cruzamentos e trabalhos com artistas RJ, SP, PB,MA, comunicadora performativa e Editora de Cultura do portal de comunicação popular Ocorre Diário, vivências em tambores ,escrevo rabiscos poéticos. Danço uma linguagem hibrida, mergulhado na ancestralidade negra de empretecimento, encontrando confluência na comunicação, tecnologia, emoções (raiva, alegria, fúria, felicidade, esperança, perseverança, resistência, insistência). Danço uma gambiarra de um suspiro de ar, fogo, reconstrução do olhar sobre o corpo retinto através  de diálogos  visíveis  ou não.  Atualmente desenvolvendo a performance GRITO um acontecimento nos espaços à margem dos centros, conduzo a live e diálogos. Visibilizar os Invisíveis construindo uma cartografia de um olhar empretecido de dança na internet e venho desenvolvendo a série de Videopeformances Boca da Rua durante A Pandemia. Participei do Festival Junta de Dança Contemporânea e I Congresso de dança piauiense Forma-Congresso Piauiense de Ações Formativas Performativas e Afirmativas em Dança. Danço e comunico resistência e insistência de ser negra e viver de forma empretecida.
                    Sou uma mulher híbrida  na profissão  e desenvolvo trabalhos  de assessoria de comunicação  com linguagens não  violenta.<br/>
                </p>
            </div>
        </section>
      </section>
    );
  }
}
    
export default PensamentosDecoloniais;