import React from "react";
import './Inicio.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Title from "../../components/items/Titles";
import PreSection from "../../components/items/PreSection";
import MiniSaibaMais from "../../components/MiniSaibaMais/MiniSaibaMais";
import data from "../../data";
const { carouselImagesHomePage } = data;

class Home extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    
    render() {
        return (
        <>
        <Helmet>
            <title>Arte na Rede PI</title>
            <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
            <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, projeto, piauí, ações formativas" />
        </Helmet>
            <section
                id="welcome"
                style={{
                    backgroundImage: `url(../../../image/carrossel-pagina-inicial/${carouselImagesHomePage[0]})`,
                }}
            >
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                    <div className="welcome-title">
                        <Title text="Arte na Rede Piauí" />

                        <h5>uma nova realidade para o ensino das artes integradas</h5>
                    </div>

                    <div className="welcome-plus">
                        <Link id='navItem' className="nav-link active" aria-current="page" to='/sobre-o-projeto'>
                            <button type="button" className="btn-2">Saiba mais</button>
                        </Link>
                    </div>

                    <div className="arrow">
                        <img src="./image/carrossel/Arrow.png" alt="seta" />
                    </div>
                </div>
            </section>

            <section className="section">
                <PreSection text="Sobre o projeto" />
                <div
                className="sobre-content-1"
                
                >
                    <section className="sobre-1">
                        <div className="sobre-sec">
                            <div>
                                <h3 className="title-3">O que é o Arte na Rede PI?</h3>
                                <p className="paragrafo">O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados. </p>
                            </div>

                            <div className="barra-orange" />
                        </div>

                        <div>
                            <iframe className="cities-video" src="https://www.youtube.com/embed/2yLXmXUpUIw" title="TEASER 11   EQUIPE DIRETORA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </section>

                    <section className="content">
                        <img src="./image/photos/photo-sobre-pg-inicial.png" alt="fotos do grupo" />

                        <div className="sobre-sec">
                            <div className="barra-orange" />

                            <div className="sobre-text-2">
                                <h3 className="title-3">Como atuamos?</h3>
                                <p className="paragrafo">O Projeto está configurado como uma iniciativa eminentemente democrática, uma vez que viabiliza o acesso às artes da cena de boa qualidade de forma gratuita a todas as camadas da população, notadamente a população de baixa renda.</p>
                            </div>
                        </div>

                    </section>
                </div>
            </section>

            <section>
                <MiniSaibaMais />
            </section>
        </>
        )};
}

export default Home;