import React from 'react';
import './PhotoMural.css';

export default class PhotoMural extends React.Component {
    render() {
        const { imageLink, imageDescriprion, handleClick, allPhotos } = this.props;
        return (
            <div id='photo-mural-container'>
                <div id='photo-mural-general'>
                    <div
                        onClick={ () => handleClick('previous') }
                        className="button-photo-mural"
                    >
                        <i className="fa-solid fa-angle-left" />       
                    </div>

                    <div className='image-photo-mural-container'>
                        <img id='image-photo-mural' src={ imageLink } alt={ `Projeto Arte na Rede Piauí na cidade de ${imageDescriprion}` } />
                    </div>

                    <div
                        onClick={ () => handleClick('next') }
                        className="button-photo-mural"
                    >
                        <i className="fa-solid fa-angle-right" />       
                    </div>
                </div>

                {/* <div
                    className="plus-btn"
                    onClick={ () => handleClick('all-photos') }
                >
                    { allPhotos ? 'Minimizar' : 'Ver Todas as fotos'}
                </div> */}

            </div>
        );
    }    
};
