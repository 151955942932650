import React from "react";
import { Helmet } from "react-helmet";



class error404 extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    
    render() {
        return (
            <section className="error-404">
                <Helmet>
                    <title>erro 404</title>
                    <meta name="description" content="erro" />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>
                <h1>Erro 404</h1>
                <p>Página não encontrada</p>
            </section>
        );
    }
}

export default error404;