import React from 'react';
import PhotoMural from '../../PhotoMural/PhotoMural';
import '../City.css';

import { Helmet } from 'react-helmet';

import data from '../../../data';

class Teresina extends React.Component {
    constructor () {
        super();

        this.state = {
            imageIndex: 0,
            allPhotos: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    handleClick(dados) { 
        return (button) => {
            const limitReference = dados.photos.length - 1;
            console.log('handle click');
            switch (button) {
                case 'next':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === limitReference ? 0 : prevState.imageIndex + 1,
                    }));
                case 'previous':
                    return this.setState((prevState) => ({
                        imageIndex: prevState.imageIndex === 0 ? limitReference : prevState.imageIndex - 1,
                    }));
                case 'all-photos':
                    return this.setState((prevState) => ({
                        allPhotos: !prevState.allPhotos,
                    }));
                default:
                    break;
            }
        }
    }

    render () {
    const { teresina } = data;
    const { imageIndex, allPhotos } = this.state;
        return (
            <section>
                <Helmet>
                    <title>Teresina - Arte na Rede PI</title>
                    <meta name="description" content="O projeto ARTE NA REDE, pretende inaugurar uma nova realidade para o ensino das artes integradas, dando continuidade ao processo de descentralização para outros municípios. É um projeto arrojado, com muita projeção e que se estende para outras cidades e estados." />
                    <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, danceability, cursos, oficinas, residências, seminários Políticas Públicas" />
                </Helmet>

                <section
                    style={{
                    backgroundImage: 'url("../../image/acoes-formativas/oficinas-dancando-pelo-piaui/cidades/Ponte-Estaiada-Teresina.jpg")',
                    // backgroundSize: '200%',
                    backgroundPosition: '75% 7%',
                    }}
                    className="cities-sec-1"
                >
                    <div className="cities-text-1">
                        <h3 className="title-3">TERESINA</h3>
                        <p className="paragrafo">
                        A oficina Dançando Pelo Piauí esteve na cidade entre os dias 16 e 18 de maio no Auditório da Biblioteca Cromwell de Carvalho ministrada pelo bailarino e coreógrafo Paulim Beltrão e apresentação artística da obra "Aparições de Dança" do Bailarino e Coreógrafo Datan Izaká no dia 18 de maio. Confira um pouco do que aconteceu.</p>
                    </div>
                </section>

                <section className="cities-sec-2">
                    <h4 className="title-4">Videos</h4>
                    <div>
                        <iframe className="cities-video" src="https://www.youtube.com/embed/wBsxn1qi69I" title="TEASER 1  ARIEL ELOI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                    <div>
                        <iframe className="cities-video" src="https://www.youtube.com/embed/803DUATQmtw" title="TEASER 2   JÚLIA FERNANDES E DÉBORA RAQUEL" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </section>

                <section className="cities-sec-3">
                    <h4 className="title-4">Fotos</h4>
                    <PhotoMural
                        imageLink={ `../../image/photos/teresina/${teresina.photos[imageIndex]}` }
                        imageDescription='Teresina'
                        handleClick={ this.handleClick(teresina) }
                        allPhotos={ allPhotos }
                    />
                    {/* <div className='all-photos-general'>
                    {allPhotos
                        && (
                        teresina.photos.map((photo) => {
                            return (
                                <div className='all-photos-container'  key={ photo }>
                                    <div className='all-photo-container'>
                                        <img
                                            className='all-photos-image'
                                            src={`../../image/photos/teresina/${photo}`}
                                            alt={ 'Projeto Arte na Rede Piauí na cidade de Teresina' }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )}
                    </div> */}
                </section>
            </section>
        );
    }
};

export default Teresina;
