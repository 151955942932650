import React from "react";

import PhotoMural from "../../../../components/PhotoMural/PhotoMural";
import data from "../../../../data";

import { Helmet } from "react-helmet";

class OficinaDeEnvultacoes extends React.Component {
  constructor () {
    super();

    this.state = {
        imageIndex: 0,
        allPhotos: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  handleClick(dados) { 
    return (button) => {
        const limitReference = dados.photos.length - 1;
        console.log('handle click');
        switch (button) {
            case 'next':
                return this.setState((prevState) => ({
                    imageIndex: prevState.imageIndex === limitReference ? 0 : prevState.imageIndex + 1,
                }));
            case 'previous':
                return this.setState((prevState) => ({
                    imageIndex: prevState.imageIndex === 0 ? limitReference : prevState.imageIndex - 1,
                }));
            case 'all-photos':
                return this.setState((prevState) => ({
                    allPhotos: !prevState.allPhotos,
                }));
            default:
                break;
        }
    }
}

  render() {
    const { cursosOficinasResidencias } = data;
    const { oficinaDeEnvultacoes } = cursosOficinasResidencias;
    const { imageIndex, allPhotos } = this.state;
    return (
        <section>
            <Helmet>
                <title>Oficina de Práticas de Envultações - Arte na Rede PI</title>
                <meta name="description" content="envultar (en + vulto + ar): tornar-se vulto, desaparecer a forma humana para multiplicar presenças; A ideia mobilizadora desta oficina é criar, através da dança, tecnologias de transformação nas quais cada corpo é uma coletividade, uma constelações de corpos e, por isso, as histórias de cada pessoa podem ser tratadas como micromitologias coreográficas. Partindo destas duas noções, dançaremos situações de desaparecimento ou envultamento no qual a imagem do indivíduo desaparece para que se revele sua coletividade de entes, seres e presenças." />
                <meta name="keywords" content="arte, cultura, piauí, dança, dança contemporânea, sobre, projeto, piauí, ações formativas, artistas, envultações" />
            </Helmet>
            <section className="pre-section" id="oficinas-title">
                <h1 className="about-project oficinas-title" >Cursos | Oficinas | Residências</h1>
            </section>

            <section 
            id="oficinas-dancando-pelo-piaui-container"
            >
                <section className="oficinas-dancando-pelo-piaui-sec-1">
                    <h3 className="title-3">Oficina Prática de Envultações</h3>
                    <p className="paragrafo">envultar (en + vulto + ar): tornar-se vulto, desaparecer a forma humana para multiplicar presenças;  A ideia mobilizadora desta oficina é criar, através da dança, tecnologias de transformação nas quais cada corpo é uma coletividade,  uma constelações de corpos e, por isso, as histórias de cada pessoa podem ser tratadas como micromitologias coreográficas. Partindo destas duas noções, dançaremos situações de desaparecimento ou envultamento no qual a imagem do indivíduo desaparece para que se revele sua coletividade de entes, seres e presenças.</p>
                </section>
            </section>
        <section className="cities-sec-2">
            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
                <h5 className="title-2">Mesa 1: Pensamentos Decoloniais</h5>
                <iframe className="cities-video" src="https://www.youtube.com/embed/mWFvKcfSvKk" title="1º Seminário ARTE NA REDE PI #2 - Mesa 1: Pensamentos Decoloniais" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div> */}

            <section className="cities-sec-3">
                    <PhotoMural
                        imageLink={ `../../image/acoes-formativas/cursos-oficinas-residencias/oficina-práticas-de-envultacoes/${oficinaDeEnvultacoes.photos[imageIndex]}` }
                        imageDescription='Teresina'
                        handleClick={ this.handleClick(oficinaDeEnvultacoes) }
                        allPhotos={ allPhotos }
                    />
                    {/* <div className='all-photos-general'>
                    {allPhotos
                        && (
                            oficinaDeEnvultacoes.photos.map((photo) => {
                            return (
                                <div className='all-photos-container'  key={ photo }>
                                    <div className='all-photo-container'>
                                        <img
                                            className='all-photos-image'
                                            src={`../../image/acoes-formativas/cursos-oficinas-residencias/oficina-práticas-de-envultacoes/${photo}`}
                                            alt={ 'Projeto Arte na Rede Piauí na cidade de Teresina' }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    )}
                    </div> */}

                    <p className="paragrafo">Se um corpo é uma constelação de corpos, quais coletividades são movidas quando dançamos? Se experimentarmos compor mitologias com nossas pequenas vidas, quais entes habitam nossas micromitologias coreográficas? É possível dançarmos uma dança não-antropocêntrica?  Quando dizemos corpo, aqui, estamos falando sempre de relação e nunca uma exclusividade especista e anatômica do corpo. Por isso, em nossas ENVULTAÇÕES  a dança acontece numa variação de corpos. Portanto, corpo pode ser um objeto, um animal, uma pessoa viva, uma foto, uma planta, uma pessoa morta… a relação que você estabelece é que irá fazer corpo, fazer parentes e atribuir vida ou não aos corpos que co-existem contigo. Este trabalho ético deverá intensificar sua sensibilização e atenção a essas presenças que habitam suas relações diárias. </p>
                </section>

            <p><strong>Período:</strong> 9h às 12h<br/><strong>Duração:</strong> 29 de novembro a 3 de dezembro de 2021</p>
        </section>
      </section>
    );
  }
}
    
export default OficinaDeEnvultacoes;